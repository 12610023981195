const config = {
    baseUrl: "https://api.deploy.streetThings.abdulrhman.dev",
    env:"api/Builds/last-build",
    envi:"api/Builds/trigger",
    reCaptcha:"api/Builds/trigger/v2",
    logss:"api/Logs",
    hub:"logsHub", 
    sitekey:"6LdYfWEqAAAAACKc2CyhgNTMK-ucIjxfR8diLm3D",
    connectionStringKey:"InstrumentationKey=a1d7ed60-e44c-4c14-9e44-3f8a403d5453;IngestionEndpoint=https://centralindia-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralindia.livediagnostics.monitor.azure.com/;ApplicationId=454b52a9-8c3b-47e6-8d46-6adc2ae5730a"
  };
  export default config;
  