import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./index.css";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  AppInsightsContext,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "@remix-run/router";
import config from "./Constants/enviroment";
const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const isProduction =
  process.env.NODE_ENV === "production";
  console.log(process.env.NODE_ENV)
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `${config.connectionStringKey}`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
if (isProduction) {
  appInsights.loadAppInsights();
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <App />
  </AppInsightsContext.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
