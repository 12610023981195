import React from 'react'
import "./Logs.css"
import MyLog from '../../Components/MyLog/MyLog'
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"

const Logs = () => {
  const appInsights = useAppInsightsContext();
  const trackExtraLinkClick = useTrackEvent(appInsights, "Exstra Link Click")
  const handleExternalLinkClick = (event) => {
    const clickLink = event.currentTarget;
    const url = clickLink.href
    trackExtraLinkClick({ url });
  }
  return (
    <div className='container-fluid'>
      <h1>Street Things Deployer.</h1>
      <h2>Logs</h2>
      <p>Deploying the infrastructure and the back-end services to ACR then AKS is a lengthy process that will take from 10 to 15 minutes.</p>
      <p>Navigate to <a href='https://dev.streetthings.abdulrhman.dev' onClick={handleExternalLinkClick}>here</a>  to see the actual system. The availability of the system depends on the progress here.</p>
      <MyLog />
    </div>
  )
}

export default Logs